/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/*--------------------------------------------------
    [RGBA Opacity]
----------------------------------------------------*/
/***********
Theme Colors
***********/
.theme-font-color {
  color: #5b9bd1;
}

/***********
Body bg color
***********/
body {
  background: #e9ecf3;
}
body.page-md {
  background: #f3f5f9;
}

/*********** 
Page Header
***********/
/* Header search bar, toggler button & top menu */
.page-header.navbar {
  background: #3B3F51;
  border-bottom: 1px solid #e9ecf3;
  /* Top notification menu/bar */
  /* Header seaech box */
  /* Menu Toggler */
}
.page-header.navbar .top-menu .navbar-nav {
  /* Extended Dropdowns */
  /* Notification */
  /* Inbox */
  /* Tasks */
  /* User */
  /* Language */
  /* Dark version */
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle:hover {
  background-color: #3f4457;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle > i {
  color: #737a99;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-toggle .badge.badge-default {
  background-color: #5b9bd1;
  color: #5b617d;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown.open .dropdown-toggle {
  background-color: #3f4457;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown .dropdown-menu:before {
  border-bottom-color: #dce1ec;
}
.page-header.navbar .top-menu .navbar-nav > li.separator {
  background: #e9ecef;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  border: 1px solid #dce1ec;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:before {
  border-bottom-color: #cbd2e3;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
  border-bottom-color: #e3e6f0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external {
  background: #e3e6f0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > h3 {
  color: #62878f;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a {
  color: #5b9bd1;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu > li.external > a:hover {
  color: #3175af;
  text-decoration: none;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a {
  border-bottom: 1px solid #EFF2F6 !important;
  color: #888888;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu .dropdown-menu-list > li > a:hover {
  background: #f8f9fa;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a .time {
  background: #f1f1f1;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification .dropdown-menu .dropdown-menu-list > li > a:hover .time {
  background: #e4e4e4;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .circle {
  background-color: #5b9bd1;
  color: #ffffff;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox > .dropdown-toggle > .corner {
  border-color: transparent transparent transparent #5b9bd1;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-inbox .dropdown-menu .dropdown-menu-list .subject .from {
  color: #5b9bd1;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-tasks .dropdown-menu .dropdown-menu-list .progress {
  background-color: #dfe2e9;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > .username {
  color: #aeb2c4;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-toggle > i {
  color: #aeb2c4;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-user > .dropdown-menu {
  width: 195px;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > .langname {
  color: #aeb2c4;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu {
  background: #575d77;
  border: 0;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu:after {
  border-bottom-color: #575d77;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external {
  background: #464a60;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > h3 {
  color: #cbced9;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu > li.external > a:hover {
  color: #87b6dd;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a {
  color: #dadce4;
  border-bottom: 1px solid #646b89 !important;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a > i,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a > i {
  color: #bcc0cf;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a:hover,
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu .dropdown-menu-list > li a:hover {
  background: #606683;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li a {
  border-bottom: 0 !important;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-dark .dropdown-menu.dropdown-menu-default > li.divider {
  background: #646b89;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a .time {
  background: #4c5169;
}
.page-header.navbar .top-menu .navbar-nav > li.dropdown-notification.dropdown-dark .dropdown-menu .dropdown-menu-list > li > a:hover .time {
  background: #41465a;
}
.page-header.navbar .search-form {
  background: #3B3F51;
}
.page-header.navbar .search-form .input-group {
  border: 1px solid #737a99;
}
.page-header.navbar .search-form .input-group:hover {
  border-color: #666d8c;
}
.page-header.navbar .search-form .input-group .form-control {
  color: #737a99;
}
.page-header.navbar .search-form .input-group .form-control::-moz-placeholder {
  color: #707797;
  opacity: 1;
}
.page-header.navbar .search-form .input-group .form-control:-ms-input-placeholder {
  color: #707797;
}
.page-header.navbar .search-form .input-group .form-control::-webkit-input-placeholder {
  color: #707797;
}
.page-header.navbar .search-form .input-group .input-group-btn .btn.submit > i {
  color: #737a99;
}
.page-header.navbar .menu-toggler {
  background-image: url(/assets/images/sidebar-toggle-light.png);
}
.page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent {
  background: none;
  border: 1px solid #50566f;
  color: #666d8c;
}
.page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent > i {
  color: #666d8c;
}
.page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:hover, .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:focus, .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent.active {
  background: none;
  border: 1px solid #737a99;
  color: #737a99;
}
.page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:hover > i, .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent:focus > i, .page-header.navbar .page-actions .btn-group .btn-sm.btn-transparent.active > i {
  color: #737a99;
}

/* Page sidebar */
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover,
.page-sidebar {
  background-color: #ffffff;
  /* Default sidebar */
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu,
.page-sidebar .page-sidebar-menu {
  /* 1st level links */
  /* All links */
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.heading > h3,
.page-sidebar .page-sidebar-menu > li.heading > h3 {
  color: #5C9ACF;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
.page-sidebar .page-sidebar-menu > li > a {
  color: #678098;
}
@media (max-width: 991px) {
  .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a,
  .page-sidebar .page-sidebar-menu > li > a {
    /* 991px */
    border-top: 1px solid white;
  }
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i,
.page-sidebar .page-sidebar-menu > li > a > i {
  color: #a7bdcd;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu > li > a > i[class*="icon-"] {
  color: #b7c9d6;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li > a > .arrow.open:before {
  color: #b1c4d2;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a,
.page-sidebar .page-sidebar-menu > li:hover > a,
.page-sidebar .page-sidebar-menu > li.open > a {
  background: #f2f6f9;
  color: #5b9bd1;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu > li.open > a > i {
  color: #5b9bd1;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.open > a > .arrow.open:before {
  color: #67a2d4;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a,
.page-sidebar .page-sidebar-menu > li.active > a,
.page-sidebar .page-sidebar-menu > li.active.open > a {
  background: #f2f6f9;
  color: #5b9bd1;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a:hover, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a:hover,
.page-sidebar .page-sidebar-menu > li.active > a:hover,
.page-sidebar .page-sidebar-menu > li.active.open > a:hover {
  background: #f2f6f9;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > i,
.page-sidebar .page-sidebar-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu > li.active.open > a > i {
  color: #5b9bd1;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu > li.active.open > a > .arrow.open:before {
  color: #67a2d4;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu > li.active > a,
.page-sidebar .page-sidebar-menu > li.active > a {
  border-left: 3px solid #5C9ACF !important;
  margin-left: -3px;
  padding-left: 15px;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li > a > .arrow:before,
.page-sidebar .page-sidebar-menu li > a > .arrow.open:before {
  color: #b1c4d2;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu li:hover > a > .arrow.open:before {
  color: #67a2d4;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu li.active > a > .arrow.open:before {
  color: #67a2d4;
}
.page-sidebar-closed .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu:hover .sub-menu, .page-sidebar-closed
.page-sidebar .page-sidebar-menu:hover .sub-menu {
  background: #FFFFFF;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a,
.page-sidebar .page-sidebar-menu .sub-menu > li > a {
  color: #678098;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i {
  color: #a7bdcd;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class^="icon-"],
.page-sidebar .page-sidebar-menu .sub-menu > li > a > i[class*="icon-"] {
  color: #b7c9d6;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li > a > .arrow.open:before {
  color: #b1c4d2;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a {
  color: #5b9bd1;
  background: #f2f6f9 !important;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > i, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > i,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > i {
  color: #5b9bd1;
}
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow:before, .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li:hover > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.open > a > .arrow.open:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow:before,
.page-sidebar .page-sidebar-menu .sub-menu > li.active > a > .arrow.open:before {
  color: #67a2d4;
}

/******
Page Footer 
******/
.page-footer .page-footer-inner {
  color: #69789E;
}
.page-footer-fixed .page-footer {
  background-color: #e9ecf3;
}
.page-footer-fixed .page-footer .page-footer-inner {
  color: #69789E;
}

.page-boxed .page-footer .page-footer-inner {
  color: #69789E;
}
.page-footer-fixed .page-boxed .page-footer {
  background-color: #e9ecf3;
}

@media (min-width: 992px) {
  /* 992px */
  /* Sidebar menu closed */
  .page-sidebar-menu.page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(140, 178, 198, 0.2);
  }

  .page-sidebar-menu.page-sidebar-menu-closed > li:hover {
    box-shadow: 5px 5px rgba(140, 178, 198, 0.2);
  }
  .page-sidebar-menu.page-sidebar-menu-closed > li:hover > .sub-menu {
    box-shadow: 5px 5px rgba(140, 178, 198, 0.2);
  }

  /* Fixed Sidebar */
  .page-sidebar-fixed:not(.page-footer-fixed) .page-content {
    border-bottom: 0;
  }
  .page-sidebar-fixed:not(.page-footer-fixed) .page-footer .page-footer-inner {
    color: #333;
  }

  /* Boxed Layout */
  .page-boxed {
    /* Page container */
  }
  .page-boxed .page-container {
    background-color: #e9ecf3;
  }
  .page-boxed.page-sidebar-reversed .page-container {
    border-left: 0;
  }
  .page-boxed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-bottom: 0;
  }
  .page-boxed.page-sidebar-reversed.page-sidebar-fixed .page-container {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  /* Sidebar Menu Wirh Hoverable Submenu */
  .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-right: 8px solid #FFFFFF;
  }
  .page-sidebar-reversed .page-sidebar-menu-hover-submenu li:hover a > .arrow {
    border-left: 8px solid #FFFFFF;
  }
  .page-sidebar-menu-hover-submenu li:hover > .sub-menu {
    background: #FFFFFF !important;
  }
}
@media (max-width: 991px) {
  /* 991px */
  .page-header.navbar {
    padding: 0;
    /* Top menu */
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:hover {
    background-color: #3d4154;
  }
}
@media (max-width: 767px) {
  /* 767px */
  .page-header.navbar {
    background: #3B3F51;
    border-bottom: 1px solid #41465a;
    /* Top menu */
  }
  .page-header.navbar .page-top {
    background: #3B3F51;
  }
  .page-header.navbar .top-menu .navbar-nav > li.dropdown > .dropdown-toggle:hover {
    background-color: #393d4e;
  }
}
/****
 CSS3 Spinner Bar  
****/
.page-spinner-bar > div,
.block-spinner-bar > div {
  background: #6fa7d7;
}
