/******************************
 3RD PARTY PLUGIN CUSTOMIZATION 
******************************/
/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/*--------------------------------------------------
    [RGBA Opacity]
----------------------------------------------------*/
.md-shadow-z-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.md-shadow-z-1-i {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18) !important;
}

.md-shadow-z-1-hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 1px 2px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2-i {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-2-hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-3, .modal {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow-z-3-i {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.24) !important;
}

.md-shadow-z-3-hover {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-4-i {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-5-i {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-none {
  box-shadow: none !important;
}

.md-click-circle {
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}
.md-click-circle.md-click-animate {
  -webkit-animation: mdClickEffect 0.65s linear;
  -moz-animation: mdClickEffect 0.65s linear;
  animation: mdClickEffect 0.65s linear;
}

@-webkit-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    -webkit-transform: scale(2.5);
  }
}
@-moz-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    -moz-transform: scale(2.5);
  }
}
@keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    transform: scale(2.5);
  }
}
/***
Bootstrap Colorpicker
***/
.input-group.color .input-group-btn i {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 6px;
}

.colorpicker.dropdown-menu {
  padding: 5px;
}

/* change z-index when opened in modal */
.modal-open .colorpicker {
  z-index: 10055 !important;
}

/***
Bootstrap Datepaginator
***/
.datepaginator a {
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 300;
}

.datepicker .today {
  background-image: none !important;
  filter: none !important;
}

#dp-calendar {
  right: 4px !important;
}

.datepaginator .fa-angle-right:before {
  content: "\f105";
}

.datepaginator .fa-angle-left:before {
  content: "\f104";
}

/***
Bootstrap Datepicker
***/
.datepicker.dropdown-menu {
  padding: 5px;
}

.datepicker .selected {
  background-color: #909090 !important;
  background-image: none !important;
  filter: none !important;
}

.datepicker .active {
  background-color: #4b8df8 !important;
  background-image: none !important;
  filter: none !important;
}

.datepicker .active:hover {
  background-color: #2678FC !important;
  background-image: none !important;
  filter: none !important;
}

.datepicker .input-daterange input {
  text-align: left;
}

/* change z-index when opened in modal */
.modal-open .datepicker {
  z-index: 10055 !important;
}

.datepicker table td {
  color: #000;
  font-weight: 300  !important;
  font-family: 'Open Sans' !important;
}

.datepicker table th {
  color: #333;
  font-family: 'Open Sans' !important;
  font-weight: 400  !important;
}

.datepicker.dropdown-menu {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef;
}

/***
Bootstrap Daterangepicker
***/
.modal-open .daterangepicker {
  z-index: 10055 !important;
}

.daterangepicker {
  margin-top: 4px;
}

.daterangepicker td {
  text-shadow: none;
}

.daterangepicker td.active {
  background-color: #4b8df8;
  background-image: none;
  filter: none;
}

.daterangepicker th {
  font-weight: 400;
  font-size: 14px;
}

.daterangepicker .ranges input[type="text"] {
  width: 70px !important;
  font-size: 11px;
  vertical-align: middle;
}

.daterangepicker .ranges label {
  font-weight: 300;
  display: block;
}

.daterangepicker .ranges {
  width: 170px;
}
.daterangepicker .ranges ul > li.active {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}

.daterangepicker .ranges .btn {
  margin-top: 10px;
}

.daterangepicker.dropdown-menu {
  padding: 5px;
}

.daterangepicker .ranges li {
  color: #333;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
  background: #4b8df8 !important;
  border: 1px solid #4b8df8 !important;
  color: #fff;
}

.daterangepicker .range_inputs input {
  margin-bottom: 0 !important;
}

.daterangepicker .fa-angle-right:before {
  content: "\f105";
}

.daterangepicker .fa-angle-left:before {
  content: "\f104";
}

/***
Bootstrap  Datetimepicker
***/
.datetimepicker table td {
  color: #000;
  font-weight: 300  !important;
  font-family: 'Open Sans' !important;
}

.datetimepicker table th {
  font-family: 'Open Sans' !important;
  font-weight: 400  !important;
}

.datetimepicker.dropdown-menu {
  padding: 5px;
}

.datetimepicker .active {
  background-color: #4b8df8 !important;
  background-image: none !important;
  filter: none !important;
}

.datetimepicker .active:hover {
  background-color: #2678FC !important;
  background-image: none !important;
  filter: none !important;
}

.datetimepicker .fa-angle-left:before {
  content: "\f104";
}

.datetimepicker .fa-angle-right:before {
  content: "\f105";
}

/* change z-index when opened in modal */
.modal-open .datetimepicker {
  z-index: 10055;
}

/***
Bootstrap Editable
***/
.editable-input table,
.editable-input table th,
.editable-input table td,
.editable-input table tr {
  border: 0 !important;
}

.editable-input .combodate select {
  margin-bottom: 5px;
}

/***
Jansy File Input plugin css changes
***/
.fileinput {
  margin-bottom: 0;
}
.fileinput .close {
  float: none;
}
.fileinput .input-group {
  white-space: nowrap;
  overflow: hidden;
}

/***
Bootstrap Markdown
***/
.md-input {
  padding: 5px !important;
  border-bottom: 0 !important;
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}

.md-editor {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
.md-editor .btn-toolbar {
  margin-left: 0px;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.md-editor .btn-toolbar .btn-group .btn-sm.btn {
  padding-bottom: 3px;
}
.md-editor .btn-toolbar .btn-group .btn-primary {
  padding-top: 5px;
  padding-bottom: 7px;
}

.md-editor.active {
  border: 1px solid #999999;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/***
Error state for WYSIWYG Editors
***/
.has-error .md-editor {
  border: 1px solid #f13e64 !important;
}

.has-success .md-editor {
  border: 1px solid #219174 !important;
}

.has-warning .md-editor {
  border: 1px solid #ff1b08 !important;
}

/***
Bootstrap Modal
***/
/* fix: content shifting to the right on modal open */
.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-top,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .modal-scrollable {
  overflow-y: auto !important;
}

.modal-scrollable {
  overflow: hidden !important;
}

/* BS 3.3.1 fix */
.modal-backdrop {
  position: fixed;
  bottom: 0;
}

.modal-scrollable {
  overflow: hidden !important;
  z-index: 10051;
  overflow-y: scroll !important;
}

.modal {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: 0 !important;
}
.modal .modal-body {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: 0;
}
.modal .modal-title {
  font-weight: 400;
}
.modal .modal-footer {
  border: 0 !important;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.2 ;
  filter: alpha(opacity=20) ;
}

/***
Bootstrap Select
***/
.bootstrap-select .btn {
  border-color: #e5e5e5;
}

.has-error .bootstrap-select .btn {
  border-color: #f13e64;
}

.has-success .bootstrap-select .btn {
  border-color: #219174;
}

.has-warning .bootstrap-select .btn {
  border-color: #ff1b08;
}

.bootstrap-select.open .btn {
  border-color: #999;
}

.bootstrap-select.open.dropup .btn {
  border-color: #999;
}

.bootstrap-select .btn:focus {
  outline: none !important;
  outline-offset: 0;
}

.bootstrap-select.btn-group .dropdown-menu {
  margin-top: 1px;
}

.bootstrap-select.btn-group .dropdown-menu > li > dt > .text {
  font-weight: 600;
  font-family: 'Open Sans';
  font-size: 14px;
}

.bootstrap-select.btn-group .dropdown-menu .text-muted {
  color: #999 !important;
}

.bootstrap-select .caret {
  border: 0;
  width: auto;
  height: auto;
  margin-top: -10px !important;
}

.bootstrap-select .caret:before {
  content: "\f107";
  display: inline-block;
  border: 0;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.bootstrap-select .selected i {
  color: #aaa;
}

.bootstrap-select .dropdown-menu {
  z-index: 9999 !important;
}

/***
Bootstrap Switch 
***/
.bootstrap-switch {
  border-color: #e5e5e5;
}
.bootstrap-switch.bootstrap-switch-focused {
  box-shadow: none;
  border-color: #e5e5e5;
}

/***
Color variants
***/
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #00BCD4;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-info,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-info {
  color: #fff;
  background: #89C4F4;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #26A69A;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-warning,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-warning {
  color: #fff;
  background: #ff5722;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-danger,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-danger {
  color: #fff;
  background: #F3565D;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-default,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  color: #444;
  background: #ececec;
}

/* Bootstrap switch on portlet headers */
.portlet > .portlet-title > .actions > .bootstrap-switch {
  border-color: transparent;
  margin-top: 0px;
}

/***
Bootstrap Time Picker
***/
.bootstrap-timepicker-widget table td a {
  padding: 4px 0;
}

.bootstrap-timepicker-widget input,
.bootstrap-timepicker-widget input:focus {
  outline: none !important;
  border: 0;
}

.modal-open .bootstrap-timepicker-widget {
  z-index: 10055 !important;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before,
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  top: auto;
}

/***
Toastr Notifications
***/
.toast {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51a351;
}

.toast-error {
  background-color: #bd362f;
}

.toast-info {
  background-color: #2f96b4;
}

.toast-warning {
  background-color: #f89406;
}

.toast .toast-close-button {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  text-indent: -100000px;
  width: 11px;
  height: 16px;
  background-repeat: no-repeat !important;
  background-image: url("../img/portlet-remove-icon-white.png") !important;
}

.toast-top-center {
  top: 12px;
  margin: 0 auto;
  left: 50%;
  margin-left: -150px;
}
.toast-top-center .toast {
  margin: 0 !important;
}

.toast-bottom-center {
  bottom: 12px;
  margin: 0 auto;
  left: 50%;
  margin-left: -150px;
}
.toast-bottom-center .toast {
  margin: 0 !important;
}

/***
Bootstrap Wizard
***/
.form-wizard .progress {
  margin-bottom: 30px;
}
.form-wizard .steps {
  padding: 10px 0;
  margin-bottom: 15px;
  background-color: #fff;
  background-image: none;
  filter: none;
  border: 0px;
  box-shadow: none;
}
.form-wizard .steps > li > a.step {
  background-color: #fff;
  background-image: none;
  filter: none;
  border: 0px;
  box-shadow: none;
}
.form-wizard .steps > li > a.step:hover {
  background: none;
}
.form-wizard .steps > li > a.step > .number {
  background-color: #eee;
  display: inline-block;
  text-align: center !important;
  font-size: 16px;
  font-weight: 300;
  padding: 11px 15px 13px 15px;
  margin-right: 10px;
  height: 45px;
  width: 45px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
.form-wizard .steps > li > a.step > .desc {
  display: inline-block;
  font-size: 16px;
  font-weight: 300;
}
.form-wizard .steps > li > a.step > .desc > i {
  display: none;
}
.form-wizard .steps > li.active > a.step .number {
  background-color: #26A69A;
  color: #fff;
}
.form-wizard .steps > li.active > a.step .desc {
  color: #333;
}
.form-wizard .steps > li.done > a.step .number {
  background-color: #ff5722;
  color: #fff;
}
.form-wizard .steps > li.done > a.step .desc {
  color: #333;
}
.form-wizard .steps > li.done > a.step .desc i {
  font-size: 12px;
  font-weight: normal;
  color: #999;
  display: inline-block;
}

@media (min-width: 768px) and (max-width: 1200px) {
  /* 768px & 1200px */
  .form-wizard .step .desc {
    margin-top: 10px;
    display: block;
  }
}
@media (max-width: 768px) {
  /* 768px */
  .form-wizard .steps > li > a {
    text-align: left;
  }
}
/*!
 * Tab drop for Bootstrap
 */
.tabbable-tabdrop .nav-tabs,
.tabbable-tabdrop .nav-pills {
  position: relative;
}
.tabbable-tabdrop .nav-tabs .caret,
.tabbable-tabdrop .nav-pills .caret {
  display: none;
}
.tabbable-tabdrop .nav-tabs .tabdrop .dropdown-toggle i,
.tabbable-tabdrop .nav-pills .tabdrop .dropdown-toggle i {
  font-size: 14px;
}
.tabbable-tabdrop .dropdown-menu:after {
  right: 10px;
  left: auto;
}
.tabbable-tabdrop .dropdown-menu:before {
  right: 9px;
  left: auto;
}

/***
wysihtml5
***/
.wysihtml5-sandbox {
  width: 100% !important;
}

.wysihtml5-toolbar li {
  margin: 0px;
  height: 29px;
}

.wysihtml5-toolbar li .dropdown-menu {
  margin-top: 5px;
}

/***
Error state for WYSIWYG Editors
***/
.has-error .wysihtml5-sandbox {
  border: 1px solid #f13e64 !important;
}

.has-success .wysihtml5-sandbox {
  border: 1px solid #219174 !important;
}

.has-warning .wysihtml5-sandbox {
  border: 1px solid #ff1b08 !important;
}

/***
Error state for WYSIWYG Editors
***/
.note-editor {
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
.note-editor .note-toolbar {
  border-bottom: 1px solid #e5e5e5;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.note-editor .note-statusbar {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}
.note-editor .note-statusbar .note-resizebar {
  border-top: 1px solid #e5e5e5;
}
.note-editor.fullscreen {
  z-index: 11000;
  width: 100% !important;
}
.note-editor .dropdown-menu:before {
  left: 9px;
  right: auto;
}
.note-editor .dropdown-menu:after {
  left: 10px;
  right: auto;
}

.note-link-dialog .checkbox {
  margin-left: 20px;
}

.has-error .note-editor {
  border: 1px solid #f13e64 !important;
}

.has-success .note-editor {
  border: 1px solid #219174 !important;
}

.has-warning .note-editor {
  border: 1px solid #ff1b08 !important;
}

/***
Bootstrap GtreeTable
***/
.gtreetable .dropdown-menu {
  margin-top: 0px;
}
.gtreetable .dropdown-menu:after, .gtreetable .dropdown-menu:before {
  display: none !important;
}
.gtreetable .node-action .form-control {
  position: relative;
  top: 2px;
  display: inline-block;
}
.gtreetable .node-icon-selected,
.gtreetable .node-icon-handle,
.gtreetable .node-icon-ce,
.gtreetable .node-icon-type {
  opacity: 0.6 ;
  filter: alpha(opacity=60) ;
}

/***
Bootbox
***/
.bootbox .bootbox-close-button {
  margin-top: 0px !important;
}

/*--------------------------------------------------
    [CSS Animation Amchart]
----------------------------------------------------*/
.CSSAnimationChart {
  width: 100%;
  height: 500px;
}
.CSSAnimationChart a {
  color: #bbb !important;
  font-size: 12px !important;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 3px 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -31px;
  }
}
.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0px;
  }
  100% {
    stroke-opacity: 0;
    stroke-width: 50px;
  }
}
.amcharts-graph-column-front {
  -webkit-transition: all .3s .3s ease-out;
  transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.amcharts-graph-g3 {
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-dasharray: 500%;
  stroke-dasharray: 0 \0;
  /* fixes IE prob */
  stroke-dashoffset: 0 \0;
  /* fixes IE prob */
  -webkit-animation: am-draw 40s;
  animation: am-draw 40s;
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
/*--------------------------------------------------
    [Mao Amchart]
----------------------------------------------------*/
.mapChart {
  width: 100%;
  height: 500px;
}
.mapChart a {
  color: #bbb !important;
  font-size: 12px !important;
}

/*--------------------------------------------------
    [Column Line]
----------------------------------------------------*/
.columnLine {
  width: 100%;
  height: 350px;
  font-size: 11px;
}

.amcharts-graph-graph2 .amcharts-graph-stroke {
  stroke-dasharray: 4px 5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px;
  }
}
@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -28px;
  }
}
/*--------------------------------------------------
    [Animated Pie Chart]
----------------------------------------------------*/
.animated-pie-chart {
  width: 100%;
  height: 350px;
  font-size: 11px;
}

/***
CKEditor css changes
***/
.cke {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
}
.cke .cke-top {
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.cke .cke-bottom {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}

.cke_bottom,
.cke_inner,
.cke_top,
.cke_reset,
.cke_dialog_title,
.cke_dialog_footer,
.cke_dialog {
  background-image: none !important;
  filter: none;
  border-top: 0;
  border-bottom: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none;
}

.cke_dialog_ui_button,
.cke_dialog_tab {
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

.cke_dialog_ui_button:hover,
.cke_dialog_tab:hover {
  text-decoration: none;
  text-shadow: none;
}

.cke_dialog_ui_input_text {
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.cke_combo_button,
.cke_button,
.cke_toolbar,
.cke_toolgroup {
  background-image: none !important;
  filter: none !important;
  border: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.cke_button,
.cke_combo_button,
.cke_panel_grouptitle,
.cke_hc.cke_panel_listItem a {
  background-image: none !important;
  filter: none;
  text-shadow: none;
}

.cke_button:hover,
.cke_combo_button:hover {
  background-color: #ddd;
}

.cke_toolbar_break {
  background-image: none !important;
  filter: none !important;
  border: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
}

/***
Error state for WYSIWYG Editors
***/
.has-error .cke {
  border: 1px solid #f13e64 !important;
}

.has-success .cke {
  border: 1px solid #219174 !important;
}

.has-warning .cke {
  border: 1px solid #ff1b08 !important;
}

/***
Clockface
***/
.modal-open .clockface {
  z-index: 10055 !important;
}

.clockface .cell .inner.active,
.clockface .cell .outer.active {
  background-color: #4b8df8 !important;
  background-image: none;
  filter: none;
}

/***
Datatables Plugin(in v1.3)
***/
.dataTable {
  width: 100%;
  clear: both;
  margin-top: 5px;
}

.dataTables_filter .form-control {
  margin-left: 4px;
}
.dataTables_filter label {
  line-height: 32px;
}

.dataTable .row-details {
  margin-top: 3px;
  display: inline-block;
  cursor: pointer;
  width: 14px;
  height: 14px;
}

.dataTable .row-details.row-details-close {
  background: url("../img/datatable-row-openclose.png") no-repeat 0 0;
}

.dataTable .row-details.row-details-open {
  background: url("../img/datatable-row-openclose.png") no-repeat 0 -23px;
}

.dataTable .details {
  background-color: #eee;
}

.dataTable .details td,
.dataTable .details th {
  padding: 4px;
  background: none;
  border: 0;
}

.dataTable .details tr:hover td,
.dataTable .details tr:hover th {
  background: none;
}

.dataTable .details tr:nth-child(odd) td,
.dataTable .details tr:nth-child(odd) th {
  background-color: #eee;
}

.dataTable .details tr:nth-child(even) td,
.dataTable .details tr:nth-child(even) th {
  background-color: #eee;
}

.dataTable > thead > tr > th.sorting,
.dataTable > thead > tr > th.sorting_asc,
.dataTable > thead > tr > th.sorting_desc {
  padding-right: 18px;
}

.dataTable .table-checkbox {
  width: 8px !important;
}

@media (max-width: 768px) {
  .dataTables_wrapper .dataTables_length .form-control,
  .dataTables_wrapper .dataTables_filter .form-control {
    display: inline-block;
  }

  .dataTables_wrapper .dataTables_info {
    top: 17px;
  }

  .dataTables_wrapper .dataTables_paginate {
    margin-top: -15px;
  }
}
@media (max-width: 480px) {
  .dataTables_wrapper .dataTables_filter .form-control {
    width: 175px !important;
  }

  .dataTables_wrapper .dataTables_paginate {
    float: left;
    margin-top: 20px;
  }
}
.dataTables_processing {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: 0;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.dataTables_processing span {
  line-height: 15px;
  vertical-align: middle;
}

.dataTables_empty {
  text-align: center;
}

/***
TableTools
***/
.tabletools-btn-group {
  margin: 0 0 10px 0;
}
.tabletools-btn-group > .btn {
  margin-right: 5px;
}
.tabletools-btn-group > .btn:last-child {
  margin-right: 0;
}

.tabletools-dropdown-on-portlet {
  margin-top: -45px;
  float: right;
}
.tabletools-dropdown-on-portlet > .btn {
  margin-right: 5px;
}
.tabletools-dropdown-on-portlet > .btn:last-child {
  margin-right: 0;
}

.DTTT_Print {
  background-color: #fff;
}
.DTTT_Print .DTTT_PrintMessage {
  display: none;
}
@media print {
  .DTTT_Print .DTTT_PrintMessage {
    display: inline-block;
  }
}
.DTTT_Print .DTTT_Print_Info {
  display: block;
  position: fixed;
  top: 35px;
  font-size: 18px;
  width: 700px;
  left: 50%;
  margin-left: -350px;
  text-align: center;
}
.DTTT_Print .page-sidebar,
.DTTT_Print .page-header,
.DTTT_Print .page-footer {
  display: none;
}
.DTTT_Print .page-content-wrapper {
  float: none;
}
.DTTT_Print .row,
.DTTT_Print [class*="col-"] {
  padding: 0;
  margin: 0;
}
.DTTT_Print .page-content {
  margin: 50px auto !important;
  border: 0 !important;
  width: 800px !important;
  padding: 0 !important;
}
.DTTT_Print .page-content .portlet {
  border: 0;
  padding: 0;
}
.DTTT_Print .page-content .portlet .portlet-body {
  padding: 0;
}
.DTTT_Print .page-content .dataTables_wrapper {
  padding: 0;
  margin: 0;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
}
@media print {
  .DTTT_Print .page-content .dataTables_wrapper {
    box-shadow: none;
  }
}

/***
Extended pagination
***/
.paging_bootstrap_extended {
  margin: 0 !important;
  padding: 0 !important;
  float: none !important;
  font-size: 13px;
}

.dataTables_extended_wrapper .seperator {
  padding: 0 2px;
}

.dataTables_extended_wrapper div.dataTables_paginate,
.dataTables_extended_wrapper div.dataTables_length,
.dataTables_extended_wrapper div.dataTables_info {
  display: inline-block;
  float: none !important;
  padding: 0 !important;
  margin: 0 !important;
  position: static !important;
}

@media (max-width: 480px) {
  .dataTables_extended_wrapper div.dataTables_paginate,
  .dataTables_extended_wrapper div.dataTables_length,
  .dataTables_extended_wrapper div.dataTables_info {
    display: block;
    margin-bottom: 10px !important;
  }

  .dataTables_extended_wrapper .seperator {
    display: none !important;
  }
}
.dataTables_extended_wrapper div.dataTables_length label {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13px;
  float: none !important;
  display: inline-block !important;
}

.table-container .table-actions-wrapper {
  display: none;
}

/***
Scroller Extension
***/
.dataTables_scroll {
  margin-bottom: 10px;
}

.dataTables_scrollHead {
  border-bottom: 2px solid #ddd !important;
}
.dataTables_scrollHead thead th {
  border-bottom: 0 !important;
}

.dataTables_scrollBody {
  border-bottom: 1px solid #ddd !important;
}

.DTTT_Print_Info {
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  background: #eee;
  padding: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.DTTT_Print_Info > h6 {
  font-size: 16px;
  font-weight: 400;
}

/***
Dropzone css changes(new in v1.1.1)
***/
.dropzone {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

/***
Fancy box fix overlay fix
***/
.fancybox-overlay {
  z-index: 100000;
}

.fancybox-opened {
  z-index: 100001;
}

/***
FuelUX Spinners
***/
.spinner-buttons.btn-group-vertical .btn {
  text-align: center;
  margin: 0;
  height: 17px;
  width: 22px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;
}
.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-up {
  border-radius: 0;
  border-top-right-radius: 2px !important;
}
.page-style-rounded .spinner-buttons.btn-group-vertical .btn.spinner-down {
  border-radius: 0;
  border-bottom-right-radius: 2px;
}

/***
Fullcalendar
***/
/* External events */
.external-event {
  display: inline-block;
  cursor: move;
  margin-bottom: 5px;
  margin-left: 5px;
}

.fc-scroller {
  overflow-y: auto;
  overflow-x: hidden;
}

.fc-month-view .fc-scroller {
  height: auto !important;
}

.portlet .event-form-title {
  font-size: 14px;
  margin-top: 4px;
  font-weight: 400;
  margin-bottom: 10px;
}
.portlet.calendar .fc-button {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  border: 0;
  height: 35px;
  padding: 6px 8px 7px 8px;
  margin-left: 2px;
  border-top-style: none;
  border-bottom-style: none;
  border-right-style: solid;
  border-left-style: solid;
  border-color: #ddd;
  background: transparent;
  color: #fff;
  top: -45px;
  outline: none !important;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  border-radius: 2px 2px 0 0;
}
.portlet.calendar .fc-toolbar {
  margin-bottom: 0px;
}
.portlet.calendar .fc-toolbar h2 {
  margin-top: 3px;
  font-size: 17px;
}
.portlet.calendar .fc-header {
  margin-bottom: -21px;
}
.portlet.calendar .fc-button-prev {
  padding-right: 10px;
  padding-left: 8px;
}
.portlet.calendar .fc-button-next {
  padding-right: 8px;
  padding-left: 10px;
}
.portlet.calendar .fc-button.fc-state-active, .portlet.calendar .fc-button.fc-state-hover {
  color: #666;
  background-color: #fff;
}
.portlet.calendar .fc-button.fc-state-disabled {
  color: #ddd;
}
.portlet.calendar .fc-button .fc-icon-left-single-arrow {
  font-family: FontAwesome;
  font-size: 16px;
}
.portlet.calendar .fc-button .fc-icon-left-single-arrow:after {
  content: "";
}
.portlet.calendar .fc-button .fc-icon-left-single-arrow:before {
  content: "\f104";
}
.portlet.calendar .fc-button .fc-icon-right-single-arrow {
  font-family: FontAwesome;
  font-size: 16px;
}
.portlet.calendar .fc-button .fc-icon-right-single-arrow:after {
  content: "";
}
.portlet.calendar .fc-button .fc-icon-right-single-arrow:before {
  content: "\f105";
}
.portlet.calendar .fc-text-arrow {
  font-size: 22px;
  font-family: "Courier New", Courier, monospace;
  vertical-align: baseline;
}
.portlet.calendar .fc-event {
  border: 0px;
  background-color: #69a4e0;
  color: #fff;
}
.portlet.calendar .fc-event .fc-content {
  border: 0px;
}
.portlet.calendar .fc-event .fc-time {
  float: left;
  text-align: left;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}
.portlet.calendar .fc-event .fc-title {
  text-align: left;
  float: left;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}
.portlet.calendar .fc-header-title h2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #111;
}
.portlet.calendar .fc-widget-header {
  background-image: none;
  filter: none;
  background-color: #eee;
  text-transform: uppercase;
  font-weight: 300;
}
.portlet.calendar.light .fc-button {
  top: -60px;
  color: #666;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 35px;
}
.portlet.calendar.light .fc-button .fc-text-arrow {
  margin-top: -6px;
  display: inline-block;
}
.portlet.calendar.light .fc-button.fc-state-active, .portlet.calendar.light .fc-button.fc-state-hover {
  color: #333;
  border-bottom: 2px solid #26A69A;
}
.portlet.calendar.light .fc-button.fc-state-disabled {
  color: #aaa;
}
.portlet.calendar .mobile .fc-button {
  padding: 0px 6px 20px 6px;
  margin-left: 2px;
  border: 0;
  background-color: #ddd;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #000;
  text-shadow: none;
  text-align: center;
}
.portlet.calendar .mobile .fc-button.fc-state-hover, .portlet.calendar .mobile .fc-button.fc-state-active {
  background-color: #eee;
}
.portlet.calendar .mobile .fc-button.fc-state-disabled {
  color: #bbb;
}
.portlet.calendar .mobile .fc-button-prev {
  margin-right: 5px;
  margin-top: -2px;
}
.portlet.calendar .mobile .fc-button-next {
  margin-right: -0px;
  margin-top: -2px;
}
.portlet.calendar .mobile .fc-header-space {
  margin: 0px;
  padding: 0px;
  width: 0px;
}
.portlet.calendar .mobile .fc-header-left {
  position: absolute;
  z-index: 10;
}
.portlet.calendar .mobile .fc-header-left .fc-button {
  top: -2px;
}
.portlet.calendar .mobile .fc-header-right {
  position: relative;
  right: 0;
}
.portlet.calendar .mobile .fc-header-right .fc-button {
  top: 35px;
}
.portlet.calendar .mobile .fc-content {
  margin-top: 53px;
}

.portlet.calendar.light .fc-button {
  top: -47px;
}

/***
Google Maps
***/
.gmaps {
  height: 300px;
  width: 100%;
  /* important!  bootstrap sets max-width on img to 100% which conflicts with google map canvas*/
}
.gmaps img {
  max-width: none;
}

#gmap_static div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  height: 100%;
  display: block;
  height: 300px;
}

#gmap_routes_instructions {
  margin-top: 10px;
  margin-bottom: 0px;
}

/***
Gritter notification modify
***/
#gritter-notice-wrapper {
  right: 1px !important;
}

.gritter-close {
  left: auto !important;
  right: 3px !important;
}

.gritter-title {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 300;
}

/***
Dashboard Charts(new in v1.2.1)
***/
.easy-pie-chart,
.sparkline-chart {
  text-align: center;
}

.sparkline-chart {
  margin-top: 15px;
  position: relative;
}

.easy-pie-chart .number {
  font-size: 14px !important;
  font-weight: 300;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 75px;
  height: 75px;
  line-height: 75px;
}
.easy-pie-chart .number canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.sparkline-chart .number {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.sparkline-chart .title,
.easy-pie-chart .title {
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.sparkline-chart .title:hover,
.easy-pie-chart .title:hover {
  color: #666;
  text-decoration: none;
}

.sparkline-chart .title > i,
.easy-pie-chart .title > i {
  margin-top: 5px;
}

/***
jQuery File Upload
***/
.blueimp-gallery .close {
  background-image: url("../img/portlet-remove-icon-white.png") !important;
  margin-top: -2px;
}

.blueimp-gallery .prev,
.blueimp-gallery .next {
  border-radius: 23px !important;
}

/***
jQuery Multi Select
***/
.ms-container .ms-list {
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ms-container .ms-optgroup-label {
  font-size: 14px;
}

.ms-container .ms-selectable li.ms-elem-selectable,
.ms-container .ms-selection li.ms-elem-selection {
  font-size: 13px;
}

.ms-container .ms-list.ms-focus {
  border-color: #999999;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ms-container .ms-selectable li.ms-hover,
.ms-container .ms-selection li.ms-hover {
  color: #333;
  background-color: #eee;
}

.ms-container .form-control {
  margin-bottom: 5px;
}

/***
jQuery Notific8 Plugin
***/
.jquery-notific8-message {
  font-size: 13px;
}

[class*="jquery-notific8"],
[class*="jquery-notific8"]:after,
[class*="jquery-notific8"]:before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.right .jquery-notific8-close-sticky span,
.left .jquery-notific8-close-sticky span {
  font-size: 10px;
}

.jquery-notific8-heading {
  font-weight: 300;
  font-size: 16px;
}

/***
Password Strength(in v1.4)
***/
.password-strength .password-verdict {
  display: inline-block;
  margin-top: 6px;
  margin-left: 5px;
}

.password-strength .progress {
  margin-top: 5px;
  margin-bottom: 0;
}

.password-strength .progress-bar {
  padding: 2px;
}

/***
jQuery Slimscroll
***/
.scroller {
  padding: 0px;
  margin: 0px;
  padding-right: 12px;
  overflow: hidden;
}

.scroller-footer {
  margin-top: 10px;
}
.scroller-footer:before, .scroller-footer:after {
  content: " ";
  display: table;
}
.scroller-footer:after {
  clear: both;
}

.portlet-body .slimScrollBar {
  margin-right: 0px;
}

/***
jQuery Sparkline
***/
.jqstooltip {
  width: auto !important;
  height: auto !important;
}

.easy-pie-chart,
.sparkline-chart {
  text-align: center;
}

.sparkline-chart {
  margin-top: 15px;
  position: relative;
}

.easy-pie-chart .number {
  font-size: 16px;
  font-weight: 300;
  width: 85px;
  margin: 0 auto;
}

.sparkline-chart .number {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.sparkline-chart .title,
.easy-pie-chart .title {
  display: block;
  text-align: center;
  color: #333;
  font-weight: 300;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.sparkline-chart .title:hover,
.easy-pie-chart .title:hover {
  color: #666;
  text-decoration: none;
}

.sparkline-chart .title > i,
.easy-pie-chart .title > i {
  margin-top: 5px;
}

/***
Modify jquery-tags-input plugin css
***/
div.tagsinput {
  min-height: 35px;
  height: auto !important;
  margin: 0;
  padding: 5px 5px 0px 5px;
  overflow: auto;
}

div.tagsinput span.tag {
  background: #aaa;
  color: #fff;
  border: 0;
  padding: 3px 6px;
  margin-top: 0;
  margin-bottom: 5px;
}

div.tagsinput input {
  padding: 3px 6px;
  width: 75px !important;
}

div.tagsinput span.tag a {
  color: #fff;
}

div.tagsinput .not_valid {
  color: #fff;
  padding: 3px 6px;
  background-color: #e02222;
}

/***
jQuery UI Sliders(new in v1.1.1)
***/
.slider {
  border: 0;
  padding: 0;
  display: block;
  margin: 12px 5px;
  min-height: 11px;
}

.ui-slider-vertical {
  width: 11px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -3px;
}

.ui-slider-vertical .ui-slider-handle {
  left: -3px;
}

.ui-slider-vertical,
.ui-slider-handle {
  filter: none !important;
  background-image: none !important;
}

/***
JQVMAP Plugin
***/
.jqvmap-zoomin {
  height: 16px;
  width: 16px;
  background-color: #666;
}

.jqvmap-zoomout {
  height: 16px;
  width: 16px;
  background-color: #666;
}

.vmaps {
  position: relative;
  overflow: hidden;
  height: 300px;
}

/***
Jstree
***/
.jstree-default .jstree-clicked {
  border: 0;
  background-color: #e1e1e1;
  box-shadow: none;
}

.jstree-default .jstree-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none;
}

.jstree-default .jstree-wholerow-clicked,
.jstree-wholerow .jstree-wholerow-clicked {
  background: none;
  border: 0;
  background-color: #e1e1e1;
  box-shadow: none;
}

.jstree-default .jstree-wholerow-hovered,
.jstree-wholerow .jstree-wholerow-hovered {
  border: 0;
  background-color: #eee;
  box-shadow: none;
}

.jstree-icon.icon-lg {
  margin-top: 1px;
}

.jstree-open > .jstree-anchor > .fa-folder:before {
  margin-left: 2px;
  content: "\f07c";
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
  background-repeat: no-repeat;
}

.vakata-context,
.vakata-context ul {
  padding: 0;
  min-width: 125px;
  background-color: #ffffff;
  font-size: 14px;
  font-family: "Segoe UI",Helvetica, Arial, sans-serif;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #efefef;
}

.vakata-context li {
  border: 0;
}
.vakata-context li a {
  padding: 0 10px;
  border: 0;
}
.vakata-context li a i {
  display: none;
}
.vakata-context li a .vakata-contextmenu-sep {
  display: none;
}

.vakata-context .vakata-context-hover > a,
.vakata-context li a:hover {
  background-color: #eee;
  color: #333;
  box-shadow: none;
  margin: 0;
}
.vakata-context .vakata-context-hover > a .span,
.vakata-context .vakata-context-hover > a .ins,
.vakata-context li a:hover .span,
.vakata-context li a:hover .ins {
  border: 0 !important;
}

.vakata-context li a span,
.vakata-context li a ins {
  display: none;
  border: 0 !important;
}

.vakata-context .vakata-context-separator a,
.vakata-context-rtl .vakata-context-separator a {
  margin: 0;
  border: 0;
}

.jstree-rename-input {
  background-color: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  outline: none !important;
  padding: 2px 6px !important;
  margin-right: -4px !important;
}

/***
NoUIRangeSlider
***/
.noUi-control {
  margin: 9px 0;
}

/***
Color variants
***/
.noUi-primary.noUi-connect,
.noUi-primary .noUi-connect {
  background: #00BCD4;
}

.noUi-info.noUi-connect,
.noUi-info .noUi-connect {
  background: #89C4F4;
}

.noUi-success.noUi-connect,
.noUi-success .noUi-connect {
  background: #26A69A;
}

.noUi-warning.noUi-connect,
.noUi-warning .noUi-connect {
  background: #ff5722;
}

.noUi-danger.noUi-connect,
.noUi-danger .noUi-connect {
  background: #F3565D;
}

.noUi-default.noUi-connect,
.noUi-default .noUi-connect {
  background: #c6c6c6;
}

/***
Google reCaptcha
***/
.form-recaptcha-img {
  margin-bottom: 10px;
  clear: both;
  border: 1px solid #e5e5e5;
  padding: 5px;
}

iframe[src="about:blank"] {
  display: none;
}

/***
Color variants
***/
.has-warning .select2-container .select2-choice,
.has-warning .select2-container .select2-choices {
  border-color: #ff1b08;
}

.has-warning .select2-container.select2-dropdown-open .select2-choice,
.has-warning .select2-container.select2-dropdown-open .select2-choices {
  border-color: #ff1b08;
}

.has-warning .select2-container.select2-dropdown-open .select2-choice > span {
  color: #ff1b08;
}

.has-error .select2-container .select2-choice,
.has-error .select2-container .select2-choices {
  border-color: #f13e64;
}

.has-error .select2-container.select2-dropdown-open .select2-choice,
.has-error .select2-container.select2-dropdown-open .select2-choices {
  border-color: #f13e64;
}

.has-error .select2-container.select2-dropdown-open .select2-choice > span {
  color: #f13e64;
}

.has-success .select2-container .select2-choice,
.has-success .select2-container .select2-choices {
  border-color: #219174;
}

.has-success .select2-container.select2-dropdown-open .select2-choice,
.has-success .select2-container.select2-dropdown-open .select2-choices {
  border-color: #219174;
}

.has-success .select2-container.select2-dropdown-open .select2-choice > span {
  color: #219174;
}

/* Fix z-index when select2 opened in modals*/
.modal-open .select2-drop-mask {
  z-index: 10051;
}

.modal-open .select2-drop {
  z-index: 10052;
}

.modal-open .select2-search {
  z-index: 10053;
}

/***
Bootstrap input sizes
***/
/* Large input size */
.form-control.input-lg .select2-choice {
  height: 46px;
  padding: 10px 16px;
}

.select2-container.input-lg .select2-choice .select2-arrow b {
  background-position: 0 7px;
}

/* Small input size */
.form-control.input-sm .select2-choice {
  height: 30px;
  padding: 1px 10px 5px 10px;
}

.select2-container.input-sm .select2-choice .select2-arrow b {
  background-position: 0 0px;
}

/* Portlet full screen mode */
.modal-open.page-portlet-fullscreen .select2-drop-mask {
  z-index: 10081;
}
.modal-open.page-portlet-fullscreen .select2-drop {
  z-index: 10082;
}
.modal-open.page-portlet-fullscreen .select2-search {
  z-index: 10083;
}

/***
jQuery Uniform
***/
.checker {
  margin-top: -2px !important;
  margin-right: 2px !important;
}

.checker input,
.radio input {
  outline: none !important;
}

div.checker.disabled span,
div.checker.disabled.active span {
  background-position: -152px -260px;
}

div.checker.disabled:hover,
div.radio.disabled:hover {
  cursor: not-allowed;
}

div.radio,
div.checker {
  margin-right: 0;
  margin-left: 3px;
}

/***
Morris Charts
***/
.portlet-body-morris-fit {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -15px;
}
.portlet-body-morris-fit > svg {
  -webkit-border-radius: 0 0 2px 2px;
  -moz-border-radius: 0 0 2px 2px;
  -ms-border-radius: 0 0 2px 2px;
  -o-border-radius: 0 0 2px 2px;
  border-radius: 0 0 2px 2px;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  text-align: left;
  font-weight: 400;
  font-size: 15px;
  color: #7D8C9D;
  font-family: "Open Sans", sans-serif;
}
.morris-hover.morris-default-style .morris-hover-point {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

/***
iCheck
***/
.icheck-list > label {
  display: block;
  margin-bottom: 8px;
}
.icheck-list > label:last-child {
  margin-bottom: 0;
}

.form-horizontal .icheck-inline {
  margin-top: 8px;
}
.icheck-inline > label {
  display: inline-block;
  margin-left: 15px;
}
.icheck-inline > label:first-child {
  margin-left: 0;
}

div[class*='icheckbox_'],
div[class*='iradio_'] {
  margin-right: 5px;
  top: -1px !important;
}

.icheck-colors {
  padding: 0;
  margin: 0;
  list-style: none;
}
.icheck-colors > li {
  padding: 0;
  margin: 4px;
  float: left;
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #000000;
  opacity: 0.6 ;
  filter: alpha(opacity=60) ;
}
.icheck-colors > li:first-child {
  margin-left: 0;
}
.icheck-colors > li:hover {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
  cursor: pointer;
}
.icheck-colors > li.active {
  height: 26px;
  margin-top: 0;
  opacity: 0.6 ;
  filter: alpha(opacity=60) ;
}
.icheck-colors > li.red {
  background: #d54e21;
}
.icheck-colors > li.green {
  background: #78a300;
}
.icheck-colors > li.blue {
  background: #0e76a8;
}
.icheck-colors > li.aero {
  background: #9cc2cb;
}
.icheck-colors > li.grey {
  background: #73716e;
}
.icheck-colors > li.orange {
  background: #f70;
}
.icheck-colors > li.yellow {
  background: #fc0;
}
.icheck-colors > li.pink {
  background: #ff66b5;
}
.icheck-colors > li.purple {
  background: #6a5a8c;
}

/***
AngularJS UI-select
***/
.ui-select-match-close {
  margin-top: 5px !important;
  margin-left: 5px !important;
}

.minicolors-swatch {
  border: 0;
}
