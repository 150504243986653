@import "../assets/plugins/select2.css";

$icon-font-path: "../../../fonts/";
$fa-font-path: "../../../fonts";

.growl-container {
  z-index: 10000000 !important;
}

.CodeMirror {
    height: auto;
}

.growl-item .close {
    width: 25px !important;
    height: 20px !important;
    text-indent: 0px !important;
    background-image: none !important;
}

ul.zeroconfig {
  list-style: none;
}

li.zeroconfignode:before {
  content: "\f067"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

li.zeroconfigdata:before {
  content: "\f105"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

li.zeroconfighelp:after {
  content: "\f128"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  width: 1.3em; /* same as padding-left set on li */
}

.select2 > .select2-choice.ui-select-match {
  /* Because of the inclusion of Bootstrap */
  height: 29px;
}


.ui-select-multiple input.ui-select-search {
  width: auto !important;
}

.selectize-control > .selectize-dropdown {
  top: 36px;
}
/* Some additional styling to demonstrate that append-to-body helps achieve the proper z-index layering. */
.select-box {
  background: #fff;
  position: relative;
  z-index: 1;
}
.pointer {
  cursor: pointer;
}

.modal-backdrop {
  position: relative !important;
}

#loading-bar .bar {
  background: #66cc00;
}
#loading-bar-spinner .spinner-icon {
  border-top-color:  #66cc00;
  border-left-color: #66cc00;
}

#loading-bar .peg {
  -moz-box-shadow: #66cc00 1px 0 6px 1px;
  -ms-box-shadow: #66cc00 1px 0 6px 1px;
  -webkit-box-shadow: #66cc00 1px 0 6px 1px;
  box-shadow: #66cc00 1px 0 6px 1px;
}

.table-background-light-blue {
  background-color: lightblue !important;
}

/**
* css for diff
*/
.match,
.textdiff span {
  color: gray;
}

.ins,
ins {
  color: black;
  background: #bbffbb;
}

.del,
del {
  color: black;
  background: #ffbbbb;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ui-scroll-table {
  display: table-row-group !important;
}

.modal-dialog {
  width: min-content !important;
  min-width: 600px !important;
}

/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower


/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "components/auth/login.scss";
@import "components/builds/styles.scss";
@import "components/zeroconfig/zcvalidator.scss";
@import "partials/layout/components-md.scss";
@import "partials/layout/custom.scss";
@import "partials/layout/default.scss";
@import "partials/layout/layout.scss";
@import "partials/layout/plugins-md.scss";
// endinjector
