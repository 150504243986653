.shake {
  outline-color: red;
  /* also need animation and -moz-animation */
  -webkit-animation: shake .5s linear;
}
/* also need keyframes and -moz-keyframes */
@-webkit-keyframes shake {
  8%, 41% {
    -webkit-transform: translateX(-10px);
  }
  25%, 58% {
    -webkit-transform: translateX(10px);
  }
  75% {
    -webkit-transform: translateX(-5px);
  }
  92% {
    -webkit-transform: translateX(5px);
  }
  0%, 100% {
    -webkit-transform: translateX(0);
  }
}

/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/***
Login page
***/
/* logo page */
.login {
  background-color: #E0E0E0 !important;
}

.login .logo {
  margin: 60px auto 20px auto;
  padding: 15px;
  text-align: center;
}

.login .content {
  background: url(/assets/images/bg-white-lock.png) repeat;
  width: 360px;
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
}

.login .content h3 {
  color: #eee;
}

.login .content h4 {
  color: #eee;
}

.login .content p,
.login .content label {
  color: #fff;
}

.login .content .login-form,
.login .content .forget-form {
  padding: 0px;
  margin: 0px;
}

.login .content .form-control {
  background-color: #fff;
}

.login .content .forget-form {
  display: none;
}

.login .content .register-form {
  display: none;
}

.login .content .form-title {
  font-weight: 300;
  margin-bottom: 25px;
}

.login .content .form-actions {
  background-color: transparent;
  clear: both;
  border: 0px;
  padding: 0px 30px 25px 30px;
  margin-left: -30px;
  margin-right: -30px;
}

.login .content .form-actions .checkbox {
  margin-left: 0;
  padding-left: 0;
}

.login .content .forget-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
}

.login .content .register-form .form-actions {
  border: 0;
  margin-bottom: 0;
  padding-bottom: 0px;
}

.login .content .form-actions .checkbox {
  margin-top: 8px;
  display: inline-block;
}

.login .content .form-actions .btn {
  margin-top: 1px;
}

.login .content .forget-password {
  margin-top: 25px;
}

.login .content .create-account {
  border-top: 1px dotted #eee;
  padding-top: 10px;
  margin-top: 15px;
}

.login .content .create-account a {
  display: inline-block;
  margin-top: 5px;
}

/* select2 dropdowns */
.login .content .select2-container i {
  display: inline-block;
  position: relative;
  color: #ccc;
  z-index: 1;
  top: 1px;
  margin: 4px 4px 0px -1px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  text-align: center;
}

.login .content .has-error .select2-container i {
  color: #b94a48;
}

.login .content .select2-container a span {
  font-size: 13px;
}

.login .content .select2-container a span img {
  margin-left: 4px;
}

/* footer copyright */
.login .copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px;
  color: #eee;
  font-size: 13px;
}

@media (max-width: 480px) {
  /***
  Login page
  ***/
  .login .logo {
    margin-top: 10px;
  }

  .login .content {
    padding: 30px;
    width: 222px;
  }

  .login .content h3 {
    font-size: 22px;
  }

  .login .checkbox {
    font-size: 13px;
  }
}


/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/***
Lock Page
***/


.page-lock {
  margin: auto !important;
}

.page-lock .page-logo {
  margin-bottom: 15px;
}

.page-lock .page-body {
  width: 510px;
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 15px;

  background: url(/assets/images/bg-white-lock.png) repeat;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
}

.page-lock .page-body:after,
.page-lock .page-body:before {
  display: table;
  content: "";
  line-height: 0;
}

.page-lock .page-body:after {
  clear: both;
}

.page-lock .page-footer-custom {
  margin-top: 10px;
  text-align: left !important;
  font-size: 12px;
  color: #eaeaea;
}

.page-lock img.page-lock-img {
  float: left;
  width: 200px;
  height: auto;
}

.page-lock .page-lock-info {
  padding-left: 10px;
  float: right;
  width: 280px;
}

.page-lock .page-lock-info h1 {
  margin-top: -5px;
  font-weight: 300;
  color: #fff;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 5px;
}

.page-lock .page-lock-info .email {
  color: #eee;
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 10px;
}

.page-lock .page-lock-info .locked {
  color: #333;
  font-size: 14px;
  line-height: 14px;
  font-style: normal;
}

.page-lock .page-lock-info form {
  margin: 28px 0;
}

.page-lock .page-lock-info input {
  background: #fff;
}

.page-lock .relogin {
  margin-top: 10px;
}

.page-lock .relogin a {
  color: #e1e1e1;
}

.microsoft-login-button {
  display: block;
  height: 41px;
}

.okta-login-button {
  height: 41px;
  padding: 9px;
  font-weight: 600;
  font-size: 15px;
  background: white;
  border: 1px solid;
  margin-top: 10px;
  width: 215px;
  display: block;

  img {
    height: 19px;
    vertical-align: sub;
  }
}

/*Responsive*/
@media (max-width: 768px) {
  .page-lock {
    top: 0px;
    width: 280px;
    margin-top: 20px;
    margin-left: -140px;
  }

  .page-lock .page-body {
    padding: 20px 10px;
    text-align: center;
    width: 280px;
  }

  .page-lock img.page-lock-img {
    float: none !important;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 15px;
  }

  .page-lock .page-lock-info {
    float: none !important;
    width: 260px;
    margin: 0 auto;
  }

  .page-lock .page-lock-info input {
    width: 200px !important;
  }
}
